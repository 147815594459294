<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="TagDialogTitle"></span>
    </div>
    <!-- formData.color：{{formData.color}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="标签组名称：" prop="title">
        <el-input v-model.trim="formData.title" placeholder="请输入标签组名称(50个字符以内)"></el-input>
      </el-form-item>
      <el-form-item label="标签组颜色：" prop="color">
        <el-input v-model="formData.color" placeholder="请输入标签组颜色值" style="display: none"></el-input>
        <el-row class="colorList">
          <span class="el-icon-orange pointer" @click="showColorPanel()" :style="'color' + ':' + formData.color"></span>
          <el-row class="dropdown-menu" style="display: none">
            <p>颜色标签<span class="el-icon-close" @click="closeColorPanel()"></span></p>
            <ul class="clearfix mb-10">
              <li :style="'backgroundColor' + ':' + item.color" class="pointer" v-for="(item, index) of colorList" :key="index" @click="seleColor(item)">
                <template v-if="formData.color === item.color">
                  <span class="el-icon-check"></span>
                </template>
              </li>
              <li class="el-icon-delete pointer" @click="clearColor()"></li>
            </ul>
          </el-row>

        </el-row>

      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="saveTag">保 存</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { editTagGroupList, addTagGroupList } from '@/api/research/tag'

export default {
  watch: {
    formData :{
      handler (newVal) {
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    pageFlag: {
      default: '',
      type: String
    },
    show: {
      default: false,
      type: Boolean
    },
    groupInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      TagDialogTitle: '',
      formData: {
        title: '',
        color: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写标签组标题', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ],
        color: [
          { required: true, message: '请选择标签组颜色', trigger: 'blur' }
        ]
      },
      colorList: [
        {
          color: '#f5c319'
        },
        {
          color: '#4da54d'
        },
        {
          color: '#ce433f'
        },
        {
          color: '#3DA7F5'
        },
        {
          color: '#eb9316'
        },
        {
          color: '#797EC9'
        }
      ],
      menuState: null
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: '',
        color: ''
      }
      if (this.groupInfo !== null) {
        this.TagDialogTitle = '编辑标签组'
        this.formData.title = this.groupInfo.title
        // this.$set(this.formData, 'color', this.groupInfo.color)
        // console.log('formData', this.formData)
        this.formData.color = this.groupInfo.color
      } else {
        this.TagDialogTitle = '新增标签组'
      }
      this.$nextTick(() => {
        this.menuState = this.queryEle('.dropdown-menu')
      })
    },

    queryEle(str) {
      return document.querySelector(str)
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 显示颜色标签面板
    showColorPanel () {
      this.menuState.style.display = 'block'
      // let a = document.getElementsByClassName('dropdown-menu')
      // a[0].style.display = 'block'
    },

    // 关闭颜色标签面板
    closeColorPanel () {
      this.menuState.style.display = 'none'
    },

    // 选择颜色
    seleColor (item) {
      // console.log('选择颜色', item)
      this.formData.color = item.color
      this.menuState.style.display = 'none'
    },

    // 清除颜色
    clearColor () {
      this.formData.color = ''
      this.menuState.style.display = 'none'
    },

    saveTag () {
      this.$refs.formData.validate(valid => {
        if (valid) {
            if (this.groupInfo !== null) {
              editTagGroupList({
                id: this.groupInfo.id,
                title: this.formData.title,
                color: this.formData.color
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.close()
                  this.$emit('updateView')
                }
              })
            } else {
              addTagGroupList({
                title: this.formData.title,
                color: this.formData.color,
                businessType:this.pageFlag === 'studentTag'? '用户':'oj'
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('新增成功')
                  this.close()
                  this.$emit('updateView')
                }
              })
            }
        }
      })
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.colorList {
  position: relative;
  span {
    font-size: 20px;
  }
  ul {
    li {
      float: left;
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
      .el-icon-check {
        position: absolute;
        top: 0;
        width: 100%;
        line-height: 20px;
        color: #fff;
        font-size: 14px;
        text-align: center;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  .dropdown-menu {
    min-width: 232px;
    height: auto;
    padding: 5px 10px 10px 10px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    margin: 2px 0 0;
    font-size: 13px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #b6bdcc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.12), 0 1px 3px rgba(0,0,0,.1);
    p {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
      span {
        float: right;
        margin-top: 8px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.el-icon-delete {
  font-size: 20px;
}
</style>

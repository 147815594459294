var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.TagDialogTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签组名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标签组名称(50个字符以内)" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标签组颜色：", prop: "color" } },
            [
              _c("el-input", {
                staticStyle: { display: "none" },
                attrs: { placeholder: "请输入标签组颜色值" },
                model: {
                  value: _vm.formData.color,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "color", $$v)
                  },
                  expression: "formData.color",
                },
              }),
              _c(
                "el-row",
                { staticClass: "colorList" },
                [
                  _c("span", {
                    staticClass: "el-icon-orange pointer",
                    style: "color" + ":" + _vm.formData.color,
                    on: {
                      click: function ($event) {
                        return _vm.showColorPanel()
                      },
                    },
                  }),
                  _c(
                    "el-row",
                    {
                      staticClass: "dropdown-menu",
                      staticStyle: { display: "none" },
                    },
                    [
                      _c("p", [
                        _vm._v("颜色标签"),
                        _c("span", {
                          staticClass: "el-icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.closeColorPanel()
                            },
                          },
                        }),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "clearfix mb-10" },
                        [
                          _vm._l(_vm.colorList, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "pointer",
                                style: "backgroundColor" + ":" + item.color,
                                on: {
                                  click: function ($event) {
                                    return _vm.seleColor(item)
                                  },
                                },
                              },
                              [
                                _vm.formData.color === item.color
                                  ? [
                                      _c("span", {
                                        staticClass: "el-icon-check",
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                          _c("li", {
                            staticClass: "el-icon-delete pointer",
                            on: {
                              click: function ($event) {
                                return _vm.clearColor()
                              },
                            },
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "right", "margin-bottom": "15px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveTag } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }